export const constEnv = {
  projectTitle: "Vogue Veneer",
  storeEndPoint: "store",
  driverEndPoint: "driver",
  customerEndPoint: "customer",
  deliveryCompanyEndPoint: "delivery-company",
  designerEndPoint: "designer",
  defaultLang: "en-US",
  defaultCurrency: "USD",

  // googleMapAPIKey: "AIzaSyCLuiYBcHcOAFeskeqoWgwqJd5MxDuG4WU",
  googleMapAPIKey: "AIzaSyDfuPhBtkeJMctHCVpSisk67EOjsCyX_7U",
  googleSiteKey: "6Lfg8qsqAAAAAKzFKfyBDxmR5xsoiepUUc54vAHi",
  googleAuthClientId:
    "289261855819-hvc5shnsnr6ntgmeqrqll68btrnggc76.apps.googleusercontent.com",
  facebookAppId: "931366767692724",
  // facebookAppId: "201400718198768",

  firebaseConfig: {
    apiKey: "AIzaSyBn2FppP4VavPsayxKcEDDiqoZaDQNSBN0",
    authDomain: "vogue-veneer.firebaseapp.com",
    projectId: "vogue-veneer",
    storageBucket: "vogue-veneer.firebasestorage.app",
    messagingSenderId: "1026248185446",
    appId: "1:1026248185446:web:5515d5fff8c6c110afbfa7",
    measurementId: "G-SG3DF5X7PR",
  },

  leafletEsriApiKey:
    "AAPTxy8BH1VEsoebNVZXo8HurO9dedh1I92_4smG-Iqr8Yf7djsblkDv6BvusOgrMtJOqa47XKvigbG2a9lTZGDurCKb4R94o7c8p3VqRRhnqEzv_eyQXc1o1knTIxnuriKVesA4ULmu5zsIFHU284Vi_eji6QKWjUZFJbg5ouq_2hB1bOyE_R_S3qfFPG_hU3rm3KBbeDPEeKWSZBW08vUS9HB61RvT2rVW8dB4dGF9wDE.AT1_x1fa8WfN",

  defaultLatitude: 51.673858,
  defaultLongitude: 7.815982,
  defaultZoom: 8,

  calanderTheme: "theme-default",

  BASE_URL: "/",
  STORE_URL: `/store`,
  DRIVER_URL: `/driver`,
  CUSTOMER_URL: `/customer`,
  DELIVERY_COMPANY_URL: `/delivery-company`,
  DESIGNER_URL: `/designer`,

  ASSETS_PATH: "./assets/",
  IMAGE_PATH: `./assets/images/`,
  LOGO_PATH: `./assets/images/logo/logo.png`,
  LOGO_SVG_PATH: `./assets/images/logo/vogue-logo.svg`,

  apiBaseURL: "https://vogueveneer.com/service/",
  // apiBaseURL: "http://4.157.247.202:3024/service/",
  apiLoginURL: `https://vogueveneer.com/service/token`,
  apiURL: `https://vogueveneer.com/service/api`,
  apiStoreURL: `https://vogueveneer.com/service/api/store`,
  apiDriverURL: `https://vogueveneer.com/service/api/driver`,
  apiCustomerURL: `https://vogueveneer.com/service/api/customer`,
  apiDeliveryCompanyURL: `https://vogueveneer.com/service/api/deliverycompany`,
  apiDesignerURL: `https://vogueveneer.com/service/api/Designer`,
  apiSiteURL: `https://vogueveneer.com/service/api/customerweb`,
  apiShopingCartURL: `https://vogueveneer.com/service/api/ShoppingCart`,

  deliveryCompanyActionUrl: "https://vogueveneer.com/admin",
  threeViewUrl: "https://vogueveneer.com/floor_plan",
  chatBotBaseUrl: "https://vogueveneer.com/service/",

};
