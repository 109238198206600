import { NgModule } from "@angular/core";

import { ProductListComponent } from "./product-list/product-list.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StarRatingModule } from "angular-star-rating";
import { PipeModule } from "common/_pipe/pipe.module";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";

@NgModule({
  declarations: [ProductListComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    StarRatingModule.forRoot(),
    PipeModule,
    TranslateModule.forRoot(),
    MaterialModule,
  ],
  exports: [ProductListComponent],
})
export class SharedComponentsModule {}
