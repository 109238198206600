import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { constEnv } from "common/constants.env";
import { AuthenticationService } from "common/services/authentication.service";
import { ChatbotService } from "common/services/chatbot.service";
import { data } from "jquery";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-chat-bot",
  templateUrl: "./chat-bot.component.html",
  styleUrls: ["./chat-bot.component.css"],
})
export class ChatBotComponent implements OnInit, AfterViewChecked {
  config = constEnv;

  isChatOpen: boolean = false;
  isLoggedIn: boolean = false;
  messages: {
    sender: string;
    data: {
      message: string;
      data: any;
      imageUrl?: string;
      audioUrl?: string;
      type: string;
    };
  }[] = [];
  userMessage: string = "";
  recordedAudioUrl: string | null = null;
  private mediaRecorder!: MediaRecorder;
  private audioChunks: Blob[] = [];
  isResponseChatLoading: boolean = false;

  @ViewChild("chatWindow", { static: false }) private chatWindow!: ElementRef;
  @ViewChild("scrollAnchor", { static: false })
  private scrollAnchor!: ElementRef;

  isChatLoading: boolean = false;
  isChatLoadingError: boolean = false;
  isRecording: boolean = false;
  mediaStream!: MediaStream;
  isAudioScreen: boolean = false;

  productSlide: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 10,
    rtl: false,
    autoplay: false,
    navText: [
      "<i class='la la-long-arrow-alt-left'></i>",
      "<i class='la la-long-arrow-alt-right'></i>",
    ],
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
  };

  constructor(
    private chatbotService: ChatbotService,
    private authenticationService: AuthenticationService
  ) {
    this.userMessage = "";
  }

  ngOnInit() {
    // const token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxIiwidXNlck5hbWUiOiJSYWRoaWthLndlYm1vYmlAZ21haWwuY29tIiwidXNlckxhbmd1YWdlIjoiZW4tVVMiLCJ1c2VyQ3VycmFuY3kiOiJBRUQiLCJsYW5nSWQiOiIxIiwidXNlclR5cGUiOiJjdXN0b21lciIsImRlZmF1bHRfY3VycmVuY3lfY29kZSI6IlVTRCIsImFkZHJlc3NJZCI6IjEiLCJpc1dpemFyZFN0ZXBDb21wbGV0ZWQiOiJGYWxzZSIsIkxvZ2luRnJvbSI6IjAiLCJOYW1lIjoiUmFkaGlrYSBEZXZyZSIsImV4cCI6MTcyODk4Njk4NSwiaXNzIjoiaHR0cDovLzk0LjcyLjExNC4xMjU6MzAyNC8iLCJhdWQiOiJodHRwOi8vOTQuNzIuMTE0LjEyNTozMDI0LyJ9.rX2k3LC5DPQMPQcgGtUWzT26pQrCcx1AV4asllFdVPA"; // Retrieve Bearer token
    // if (token) {
    //   this.chatbotService.startConnection(token);
    //   // Listen for messages from the SignalR server
    //   this.chatbotService.onReceiveMessage((message) => {
    //     console.log("Init", message);
    //     let data = message.content || {};
    //     let responseText = "";
    //     if (typeof data.data !== "undefined" && data.data.length > 0) {
    //     } else {
    //       responseText = typeof message === "string" ? message : data.message;
    //       data = {
    //         message: responseText,
    //         data: [],
    //       };
    //     }
    //     // this.messages.push({ sender: "Bot", text: responseText });
    //     this.messages.push({ sender: "Bot", data: data });
    //   });
    // }
    this.isLoggedIn = !!localStorage.getItem("currentUser");
  }

  ngAfterViewChecked() {
    // this.scrollToBottom();
  }

  ngOnDestroy(): void {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  }

  startChat() {
    const currentUser = this.authenticationService.currentUserValue;
    const token = currentUser && currentUser.token ? currentUser.token : "";
    if (token !== "") {
      this.isChatLoading = true;
      this.chatbotService.startConnection(token);
      this.chatbotService.OnErrorget();

      // Listen for messages from the SignalR server
      this.chatbotService.onReceiveMessage((message) => {
        let data = message.content || {};
        let responseText = "";
        if (typeof data.data !== "undefined" && data.data.length > 0) {
        } else {
          responseText = typeof message === "string" ? message : data.message;
          data = {
            message: responseText,
            data: [],
          };
        }
        // this.messages.push({ sender: "Bot", text: responseText });
        this.messages.push({ sender: "Bot", data: data });
        this.isChatLoading = false;
        this.isResponseChatLoading = false;
        this.scrollToBottom();
      });

      // const checkRoomNameInterval = setInterval(() => {
      //   if (this.chatbotService.roomName) {
      //     clearInterval(checkRoomNameInterval);
      //     this.isResponseChatLoading = true;
      //     this.fetchMessageHistory();
      //   }
      // }, 500);
    } else {
      this.isChatLoading = true;
      setTimeout(() => {
        this.isChatLoading = false;

        this.isChatLoadingError = true;
      }, 500);
    }
  }

  fetchMessageHistory() {
    const pageNo = this.chatbotService.pageNo;
    const pageSize = this.chatbotService.pageSize;
    if (this.chatbotService.roomName) {
      this.chatbotService
        .getMessageHistory(this.chatbotService.roomName, pageNo, pageSize)
        .then((messageHistory) => {
          if (
            messageHistory &&
            Array.isArray(messageHistory?.getMessageHistory)
          ) {
            const formattedMessages = messageHistory?.getMessageHistory.map(
              (msg) => ({
                sender: msg.from == "Bot" ? "Bot" : "User",
                data: {
                  message: msg.content.message,
                  data: msg.content.data || [],
                  imageUrl: msg.content.message,
                  audioUrl: msg.content.audioUrl,
                  // type: msg.content.type,
                },
              })
            );
            this.messages = [...formattedMessages, ...this.messages];
            this.isResponseChatLoading = false;
          }
          this.scrollToBottom();
          this.isResponseChatLoading = false;
        });
      // .catch((err) => console.error("Error fetching message history:", err));
    } else {
      this.isResponseChatLoading = false;

      // console.error("Room name is not available for fetching history.");
    }
  }

  getMessageClass(msg: any): string {
    const messageType = msg.data.type || this.detectMessageType(msg.data.message);

    if (msg.sender !== "Bot") {
      return messageType === "Image" || messageType === "Audio"
        ? "audio-image"
        : "user";
    }
    return msg.data.data && msg.data.data.length > 0 ? "fullai p-0" : "ai p-0";
  }

  detectMessageType(data: string): string {
    if (this.isImageMessage(data)) return "Image";
    if (this.isAudioMessage(data)) return "Audio";
    return "text";
  }

  isImageMessage(data: string): boolean {
    return (
      typeof data === "string" &&
      (data.startsWith("data:image/") ||
        /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(data))
    );
  }

  isAudioMessage(data: string): boolean {
    return (
      typeof data === "string" &&
      (data.startsWith("data:audio/") || /\.(mp3|wav|ogg|pcm)$/i.test(data))
    );
  }

  isMediaMessage(data: string): boolean {
    return this.isImageMessage(data) || this.isAudioMessage(data);
  }

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
    if (this.isChatOpen) {
      this.startChat();
    }
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.isResponseChatLoading = true;

      // this.messages.push({ sender: "User", text: this.userMessage });
      this.messages.push({
        sender: "User",
        data: {
          message: this.userMessage,
          data: [],
          type: "text",
        },
      });

      // Send the user's message to the SignalR server
      this.chatbotService.sendMessage(this.userMessage, "Text");

      this.userMessage = "";
      this.scrollToBottom();
      // this.isResponseChatLoading = false;
    }
  }

  // Handle keydown event to send message when pressing Enter
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.sendMessage();
    }
  }

  triggerImageInput() {
    const imageInput = document.getElementById(
      "imageInput"
    ) as HTMLInputElement;
    imageInput.click();
  }

  onImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.isResponseChatLoading = true;
      const file = input.files[0];

      if (!file.type.startsWith("image/")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.chatbotService.uploadImage(file).subscribe(
          (response) => {
            this.messages.push({
              sender: "User",
              data: {
                message: "",
                data: [e.target.result], // Base64 image
                type: "Image",
              },
            });
            this.chatbotService.sendMessage(response?.data, "Image");
            this.scrollToBottom();
          },
          (error) => {}
        );
      };

      reader.readAsDataURL(file);
      input.value = ""; // Reset input value
    }
  }
  private scrollToBottom(): void {
    try {
      if (this.scrollAnchor) {
        setTimeout(() => {
          this.scrollAnchor.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 0);
      }
    } catch (err) {
      // console.error("Error scrolling to bottom:", err);
    }
  }

  showAudioScreen() {
    this.isAudioScreen = true;
  }

  closeAudioScreen() {
    this.isAudioScreen = false;
  }

  toggleRecording() {
    if (this.isRecording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }

  startRecording() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.isRecording = true;
        this.mediaStream = stream;
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.start();
        this.mediaRecorder.onerror = (event) => {
          console.error("Recording error:", event);
        };
      })
      .catch((error) => {
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          alert(
            "Microphone access is blocked. Please allow microphone access in your browser settings."
          );
        } else {
          alert("An error occurred while trying to access the microphone.");
        }
        this.isRecording = false;
      });
  }

  stopRecording() {
    this.scrollToBottom();
    if (!this.isRecording || !this.mediaRecorder) return;
    this.isResponseChatLoading = true;

    this.isRecording = false;
    this.mediaRecorder.stop();

    // Stop all media tracks
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }

    // Handle audio data
    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.isChatLoading = true;
        const audioBlob = event.data;
        const audioUrl = URL.createObjectURL(audioBlob);
        this.isAudioScreen = false;
        this.isChatLoading = false;
        // Add audio to messages
        this.addAudioMessage(audioUrl);

        // Upload audio to the server
        this.uploadAudio(audioBlob);
      }
    };
  }

  uploadAudio(audioBlob: Blob) {
    this.chatbotService.uploadAudio(audioBlob).subscribe(
      (response) => {
        // // Add the audio message to the chat
        // this.messages.push({
        //   sender: "User",
        //   data: {
        //     message: "",
        //     data: [],
        //     audioUrl: URL.createObjectURL(audioBlob),
        //   },
        // });

        // Send the server response (if any)
        this.chatbotService.sendMessage(response?.data, "Audio");
        this.isChatLoading = false;
        this.isAudioScreen = false;
        this.scrollToBottom();
      },
      (error) => {
        this.isResponseChatLoading = false;
      }
    );
  }

  addAudioMessage(audioUrl: string) {
    const audioMessage = {
      sender: "User",
      data: {
        message: "",
        data: [],
        audioUrl: audioUrl,
        type: "Audio",
      },
    };
    this.messages.push(audioMessage);
    this.scrollToBottom();
  }
}
