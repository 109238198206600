import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { constEnv } from "common/constants.env";

const baseUrl = constEnv;

const routes: Routes = [
  // { path: '', redirectTo: `login`, pathMatch: 'full' }
  { path: "", redirectTo: `home`, pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
