import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { CartService } from "common/services/site/cartservice";
import { TitleService } from "common/services/title.service";
import { Payment } from "common/_models/site/payment.modal";
import { AlertService } from "@full-fledged/alerts";
import { CustomerService } from "common/services/customers/customer.service";
import { first } from "rxjs/operators";
import { FixedElementService } from "common/services/fixed-element.service";

@Component({
  selector: "app-payment-action",
  templateUrl: "./payment-action.component.html",
  styleUrls: ["./payment-action.component.css"],
})
export class PaymentActionComponent implements OnInit {
  pageTitle;
  config = constEnv;
  currentPage;
  orderInfo: any;

  paymentInfo: Payment = null;
  headerStyle = {};

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private cart: CartService,
    private alert: AlertService,
    private customer: CustomerService,
    private fixedElementService: FixedElementService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      switch (data.page) {
        case "success":
          this.pageTitle = "Payment Success";
          break;

        case "decline":
          this.pageTitle = "Payment Decline";
          break;

        default:
          this.router.navigate([this.config.BASE_URL]);
          break;
      }
      this.currentPage = data.page;
    });

    this.titleService.setTitle(this.pageTitle);

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.fixedElementService.fixedElementHeight$.subscribe((height) => {
      const actualHeight = height + 180;
      this.headerStyle = { "margin-top": `${actualHeight}px` };
    });

    const returnRequestTicketId =
      sessionStorage.getItem(btoa("ticketId")) !== null
        ? atob(sessionStorage.getItem(btoa("ticketId")))
        : null;
    const _o =
      localStorage.getItem("_o") !== null ? localStorage.getItem("_o") : null;
    let redirectHome = true;
    if (_o !== null) {
      this.orderInfo = JSON.parse(decodeURIComponent(_o));
      this.checkPaymentStatus();
      redirectHome = false;
    }

    if (returnRequestTicketId !== null) {
      redirectHome = false;
      this.checkReturnRequestPayment(returnRequestTicketId);
    }
    // else {
    //   this.router.navigate([`${this.config.BASE_URL}home`]);
    // }

    if (redirectHome) {
      this.router.navigate([`${this.config.BASE_URL}home`]);
    }

    console.log(returnRequestTicketId);
  }

  checkPaymentStatus() {
    // const formData = new FormData();
    // formData.append('CustomerNotes', this.orderInfo._n);
    // // formData.append('promoCodeId', '');
    // // formData.append('promoCodeText', this.orderInfo._p);
    // formData.append('DeliveryAddressId', this.orderInfo._da);
    // formData.append('order_ref', this.orderInfo._ref);
    // formData.append('OrderBaseDetailId', this.orderInfo._order);
    // formData.append('IsBuyNow', this.orderInfo._isBuyNow);
    // formData.append('ProductId', (this.orderInfo._isBuyNow) ? this.orderInfo._pid : 0);

    const params = new HttpParams()
      .set("CustomerNotes", this.orderInfo._n)
      .set("DeliveryAddressId", this.orderInfo._da)
      .set("ProductId", this.orderInfo._isBuyNow ? this.orderInfo._pid : 0)
      .set("OrderBaseDetailId", this.orderInfo._order)
      .set("order_ref", this.orderInfo._ref)
      .set("IsBuyNow", this.orderInfo._isBuyNow);

    this.isLoadingService.add();
    this.cart.checkPaymentStatus(params).subscribe(
      (data) => {
        const res = data as PaymentCheckResponse;
        if (res.status === true) {
          this.paymentInfo = new Payment().deserialize(res.data);
          localStorage.removeItem("_o");
        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  checkReturnRequestPayment(ticketId) {
    this.isLoadingService.add();
    this.customer
      .returnRequestPaymentSuccess(ticketId)
      .pipe(first())
      .subscribe(
        (resp) => {
          if (resp["status"] === true) {
            this.alert.success(resp["message"]);
            sessionStorage.removeItem(btoa("ticketId"));
            this.router.navigate([
              `${this.config.CUSTOMER_URL}/return-request-list`,
            ]);
          } else {
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.isLoadingService.remove();
        }
      );
  }
}

interface PaymentCheckResponse {
  status: boolean;
  status_code: number;
  message: string;
  data: Payment;
}
