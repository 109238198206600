import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { constEnv } from "common/constants.env";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  public hubConnection: signalR.HubConnection;
  public roomName;
  pageNo: number = 0;
  pageSize: number = 20;
  constructor(private http: HttpClient) {}

  // Initialize the SignalR connection and pass the Bearer token
  public startConnection(token: string): void {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${constEnv.chatBotBaseUrl}chatBotHub`, {
        accessTokenFactory: () => token, // Pass the Bearer token here
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

      this.hubConnection
      .start()
      .then(() => {
        this.getRoomId();
        this.getRoomName();
        console.log("SignalR Connected", this.getRoomId);
        // this.getMessageHistory(this.roomName, this.pageNo, this.pageSize);
      })
      .catch((err) => console.log("Error while starting connection: " + err));
  }

  // Send a message to the SignalR server
  public sendMessage(message: string, type: string): void {
    this.hubConnection
      .invoke("SendMessage", this.roomName, message, type)
      .catch((err) => console.error(err));
  }

  public OnErrorget() {
    this.hubConnection.on("onError", function (message) {
      console.log("on error", message);
    });
  }
  // Receive a message from the SignalR server
  public onReceiveMessage(callback: (message: any) => void): void {
    this.hubConnection.on("ReceiveMessage", (message) => {
      console.log(message);
      callback(message);
    });
  }

  public getRoomId() {
    this.hubConnection.invoke("GetRoomId").catch((err) => console.error(err));
  }

  public getRoomName(): void {
    this.hubConnection.on("GetRoomName", (message) => {
      this.roomName = message;
      this.joinRoom(message);
    });
  }

  public joinRoom(roomName) {
    this.hubConnection.invoke("Join", roomName).catch((err) => {
      this.roomName = "";
      console.error(err);
    });
  }

  public getMessageHistory(
    roomName: string,
    pageNo: number,
    pageSize: number
  ): Promise<any> {
    return this.hubConnection.invoke("GetMessageHistory", roomName, pageNo, pageSize);
  }
  

  public uploadImage(file: File): Observable<any> {
    const formData: FormData = new FormData();
    // formData.append('RoomId', "");
    formData.append("RoomName", this.roomName);
    formData.append("File", file);

    return this.http.post<any>(
      `${constEnv.apiURL}/Upload/attachment`,
      formData
    );
  }

  public uploadAudio(audioBlob): Observable<any> {
    const formData: FormData = new FormData();
    // formData.append('RoomId', "");
    formData.append("RoomName", this.roomName);
    formData.append("File", audioBlob, "recording.webm");

    return this.http.post<any>(
      `${constEnv.apiURL}/Upload/audioAttachment`,
      formData
    );
  }
}
