import { Component, Input, OnInit } from "@angular/core";
import { ProductList } from "common/_models/site/product-list.modal";
import { constEnv } from "common/constants.env";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.css"],
})
export class ProductListComponent implements OnInit {
  config = constEnv;

  productList: ProductList;
  @Input() set setProductList(value: ProductList) {
    this.productList = value;
  }

  showRating: boolean = true;
  @Input() set setShowRating(value: boolean) {
    this.showRating = value;
  }

  constructor() {}

  ngOnInit() {}
}
