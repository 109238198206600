import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  VERSION,
  ViewChild,
} from "@angular/core";
import { TitleService } from "common/services/title.service";
import { DOCUMENT } from "@angular/common";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { GeneralService } from "common/services/general.service";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { FixedElementService } from "common/services/fixed-element.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit, OnDestroy {
  public version = VERSION.full;
  public conatctForm: FormGroup;

  submitted = false;

  loading = false;

  @ViewChild("f", { static: false }) myNgForm;

  headerStyle = {};

  constructor(
    private titleService: TitleService,
    @Inject(DOCUMENT) private document: Document,
    private isLoadingService: IsLoadingService,
    private general: GeneralService,
    private alert: AlertService,
    private fixedElementService: FixedElementService
  ) {}

  ngOnInit() {
    this.document.body.classList.add("front-body");
    this.titleService.setTitle("Contact");
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.fixedElementService.fixedElementHeight$.subscribe((height) => {
      const actualHeight = height + 150;
      this.headerStyle = { "margin-top": `${actualHeight}px` };
    });

    this.createContactForm();
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("front-body");
  }

  get f() {
    return this.conatctForm.controls;
  }

  createContactForm() {
    this.conatctForm = new FormGroup({
      FirstName: new FormControl(null, Validators.required),
      LastName: new FormControl(null, Validators.required),
      Email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      Phone: new FormControl(null, [
        Validators.required,
        this.validatePhoneNumber,
      ]),
      Subject: new FormControl(null, Validators.required),
      Message: new FormControl(null, Validators.required),
      recaptchaReactive: new FormControl(null, Validators.required)
    });
  }

  getErrorEmail() {
    return this.conatctForm.get("Email").hasError("required")
      ? ""
      : this.conatctForm.get("Email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  validatePhoneNumber(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const phoneNumber = control.value;

    // Check if the phone number has more than 10 digits
    if (phoneNumber && phoneNumber.replace(/\D/g, "").length > 10) {
      return { invalidPhoneNumber: true };
    }

    return null;
  }

  onSubmit() {
    this.submitted = true;

    if (this.conatctForm.invalid) {
      return;
    }


    this.isLoadingService.add();
    this.loading = true;


    this.general.sendContactInquiry(this.conatctForm.value).subscribe(
      (response) => {
        if (response["status"] === true) {
          this.alert.success(response["message"]);
          this.reset();
        } else {
          this.alert.danger(response["message"]);
        }
        this.loading = false;
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(msg);
        }
        this.loading = false;
        this.isLoadingService.remove();
      }
    );
  }

  reset() {
    this.submitted = false;
    this.loading = false;
    this.conatctForm.reset();
    this.myNgForm.resetForm();
  }

  onCaptchaResolved(captchaResponse: string): void {
    console.log("Captcha resolved with response:", captchaResponse);
  }

}
