import {
  Component,
  Renderer2,
  Inject,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { Observable } from "rxjs";
import { first, filter } from "rxjs/operators";
import { IsLoadingService } from "@service-work/is-loading";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from "@angular/router";
import { LanguageService } from "common/services/language.service";

import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";

import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";
import { GeneralService } from "common/services/general.service";
import { TitleService } from "common/services/title.service";
import { AuthenticationService } from "common/services/authentication.service";
import { FixedElementService } from "common/services/fixed-element.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "";
  isLoading: Observable<boolean>;

  config = constEnv;

  languages;
  _constatnt;

  @ViewChild("fixedElement") fixedElement!: ElementRef;

  constructor(
    private isLoadingService: IsLoadingService,
    private router: Router,
    public translate: TranslateService,
    public renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private languageService: LanguageService,
    private loaderService: LoaderService,
    private gs: GeneralService,
    private titleService: TitleService,
    private authenticationService: AuthenticationService,
    private fixedElementService: FixedElementService
  ) {
    this.checkUserRights();
    this.titleService.setTitle();
    this.languages = this.languageService.languages;
    translate.addLangs(this.languages);
    if (this.languageService.getCurrentLanguage()) {
      const browserLang = this.languageService.getCurrentLanguage();
      translate.use(
        browserLang.match(this.languages.join("|"))
          ? browserLang
          : this._constatnt.defaultLang
      );
      // translate.use(browserLang);
    } else {
      this.languageService._setLanguage(this._constatnt.defaultLang);
      translate.setDefaultLang(this._constatnt.defaultLang);
    }

    this.gs.getDefaultSettingsValue();
    translate.setTranslation(
      this.languageService.getCurrentLanguage(),
      this.languageService.getLanguageTranslations()
    );

    this.setLanguageDir();
  }

  ngOnInit() {
    this._constatnt = constEnv;
    this.isLoading = this.isLoadingService.isLoading$();

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          this.loadFronStyles(event.url);
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
        window.scrollTo(0, 0);
      });

    window.scrollTo(0, 0);

    const currentLanguage = this.languageService.getDefaultLanguage();
    const langStyle =
      currentLanguage !== null ? currentLanguage.lang_style : "ltl";

    localStorage.setItem("base_url", this._constatnt.apiURL);
    if (langStyle === "rtl") {
      this.loadStyle("./assets/css/rtl.css", "rtl-style");
    }
  }

  ngAfterViewInit(): void {
    this.updateHeight();
  }

  ngOnDestroy(): void {
    this.fixedElementService.setHeight(0); // Reset height when component is destroyed
  }

  // Listen to window resize
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.updateHeight(); // Update height dynamically
  }

  private updateHeight(): void {
    if (this.fixedElement) {
      const height = this.fixedElement.nativeElement.offsetHeight;
      this.fixedElementService.setHeight(height); // Update height in the service
    }
  }

  changeLang(language: string) {
    this.languageService._setLanguage(language);

    this.setLanguageDir();
  }

  setLanguageDir() {
    const currentLanguage = this.languageService.getDefaultLanguage();

    const langStyle =
      currentLanguage !== null ? currentLanguage.lang_style : "ltl";

    this.renderer.setAttribute(
      document.querySelector("html"),
      "lang",
      this.translate.currentLang
    );
    this.renderer.setAttribute(
      document.querySelector("html"),
      "dir",
      langStyle === "rtl" ? "rtl" : "ltl"
    );
  }

  loadStyle(styleName: string, styleId: string) {
    const head = this.document.getElementsByTagName("head")[0];

    const themeLink = this.document.getElementById(styleId) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement("link");
      style.id = styleId;
      style.rel = "stylesheet";
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  removeStyle(styleId: string) {
    const themeLink = this.document.getElementById(styleId) as HTMLLinkElement;

    if (typeof themeLink !== "undefined" && themeLink !== null) {
      themeLink.remove();
    }
  }

  loadFronStyles(url: string) {
    const urlSegment = url.split("?");
    let finalUrl = url;
    if (typeof urlSegment[0] !== "undefined") {
      finalUrl = urlSegment[0];
    }

    let isStoreUrl = false;
    if (finalUrl.indexOf(this._constatnt.storeEndPoint) > -1) {
      isStoreUrl = true;
    }

    let isDriverUrl = false;
    if (finalUrl.indexOf(this._constatnt.driverEndPoint) > -1) {
      isDriverUrl = true;
    }

    let isCustomerUrl = false;
    if (finalUrl.indexOf(this._constatnt.customerEndPoint) > -1) {
      isCustomerUrl = true;
    }

    let isDCUrl = false;
    if (finalUrl.indexOf(this._constatnt.deliveryCompanyEndPoint) > -1) {
      isDCUrl = true;
    }

    let isDesignerUrl = false;
    if (finalUrl.split("/").indexOf(this._constatnt.designerEndPoint) > -1) {
      isDesignerUrl = true;
    }

    let isLoginUrl = false;
    if (
      finalUrl.indexOf("login") > -1 ||
      finalUrl.indexOf("forgot-password") > -1 ||
      finalUrl.indexOf("register") > -1 ||
      finalUrl.indexOf("reset-password") > -1 ||
      finalUrl.indexOf("email-verify") > -1
    ) {
      isLoginUrl = true;
    }

    if (
      !isStoreUrl &&
      !isDriverUrl &&
      !isCustomerUrl &&
      !isDCUrl &&
      !isDesignerUrl &&
      !isLoginUrl
    ) {
      // this.loadStyle('assets/css/line-awesome.min.css', 'la-icons');
      this.loadStyle("./assets/css/site-theme.css", "site-theme");
    } else {
      // this.removeStyle('la-icons');
      this.removeStyle("site-theme");
    }
  }

  checkUserRights() {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // authorised so return true
      const currentUserDetail = currentUser.userdetails;
      // console.log(currentUserDetail);
      const userType = currentUserDetail.userType;

      const stateUrl = window.location.href;
      let redirectUrl = "";
      if (stateUrl.indexOf(userType) === -1) {
        switch (userType) {
          case "driver":
            redirectUrl = this.config.DRIVER_URL + "/dashboard";
            break;
          case "store":
            redirectUrl = this.config.STORE_URL + "/dashboard";
            break;
          case "designer":
            window.location.href =
              this.config.deliveryCompanyActionUrl + "/DesignerProfile/View";
            // redirectUrl = this.config.deliveryCompanyActionUrl;
            break;
          default:
            // redirectUrl = this.config.BASE_URL;
            break;
        }

        if (redirectUrl) this.router.navigate([`/${redirectUrl}`]);
      }
    }
  }

  public checkIfUserCustomer() {
    const currentUser = this.authenticationService.currentUserValue;
    if (!currentUser) {
      return true;
    }
    const currentUserDetail = currentUser.userdetails;
    const userType = currentUserDetail?.userType;

    return userType === "customer";
  }
}
