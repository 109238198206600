import { Component } from "@angular/core";
import { constEnv } from "common/constants.env";

@Component({
  selector: "app-aboutus",
  templateUrl: "./aboutus.component.html",
  styleUrls: ["./aboutus.component.css"],
})
export class AboutusComponent {
  config = constEnv;
}
