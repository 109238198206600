import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({ name: "mycurrency" })
export class MyCurrencyPipe implements PipeTransform {
  transform(
    num: any,
    currencycode: string,
    showsymbol: boolean = true,
    digits: string = ""
  ): any {
    let value = new CurrencyPipe(navigator.language).transform(
      num,
      currencycode,
      showsymbol,
      digits
    );
    //console.log(value, num, currencycode, showsymbol, digits);
    if (value) {
      let firstdigit = value.match(/\d/);
      let symbol = value.slice(0, firstdigit.index);
      let amount = value.slice(firstdigit.index);
      return symbol + " " + amount;
      // return currencycode + " " + amount;
    } else {
      return "";
    }
  }
}
