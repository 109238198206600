import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { GeneralService } from "common/services/general.service";
import { constEnv } from "common/constants.env";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "common/services/authentication.service";
import { IsLoadingService } from "@service-work/is-loading";
import { LanguageService } from "common/services/language.service";
import { CartService } from "common/services/site/cartservice";
import { EventEmitterService } from "common/services/event-emitter.service";
import { Subscription } from "rxjs";
import { ViewportScroller } from "@angular/common";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatSidenav } from "@angular/material/sidenav";
import { FixedElementService } from "common/services/fixed-element.service";

declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  animations: [
    trigger("slideInOut", [
      state("in", style({ transform: "translateX(0)" })),
      state("out", style({ transform: "translateX(-100%)" })),
      transition("in => out", animate("400ms ease-out")),
      transition("out => in", animate("400ms ease-in")),
    ]),
    trigger("slideOutIn", [
      state("in", style({ transform: "translateX(0)" })),
      state("out", style({ transform: "translateX(100%)" })),
      transition("in => out", animate("400ms ease-out")),
      transition("out => in", animate("400ms ease-in")),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  logoUrl;
  siteTitle;

  config = constEnv;
  isUserLoggedIn = false;

  cartCount = 0;
  wishListCount = 0;

  cartCountEventsubscription: Subscription;

  availableLangauges: any[] = [];
  currentLanguage: string;
  defaultLanguage: any = {};

  mainMenu: SiteMainMenu[] = null;
  console = console;

  showCategorySubMenu = false;
  categorySubMenu = [];
  @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;
  categoryMenu = [];

  headerStyle = {};

  constructor(
    private gs: GeneralService,
    private route: ActivatedRoute,
    private isLoadingService: IsLoadingService,
    private langService: LanguageService,
    public router: Router,
    private authenticationService: AuthenticationService,
    private cart: CartService,
    private eventEmitterService: EventEmitterService,
    private viewPosrtScroller: ViewportScroller,
    private fixedElementService: FixedElementService
  ) {}

  ngOnInit() {
    // this.logoUrl = './asstes/images/logo/furni-logo.svg';
    this.logoUrl = this.gs.getSiteLogo(true);
    this.siteTitle = this.config.projectTitle;

    this.isUserLoggedIn = this.authenticationService.currentUserValue
      ? true
      : false;

    this.fixedElementService.fixedElementHeight$.subscribe((height) => {
      this.headerStyle = { "margin-top": `${height}px` };
    });

    // if (this.eventEmitterService.subsVar==undefined) {
    //   this.eventEmitterService.subsVar = this.eventEmitterService.
    //   invokeSiteHeaderFunction.subscribe((name:string) => {
    //     this.getCartCount();
    //   });
    // }

    this.cartCountEventsubscription = this.cart
      .getCartCountEvent()
      .subscribe(() => {
        this.getCartCount();
      });

    this.availableLangauges = this.langService.getAvailableLanguages();
    this.currentLanguage = this.langService.getCurrentLanguage();
    this.defaultLanguage = this.langService.getDefaultLanguage();

    this.getCategory();

    this.getCartCount();
  }

  scrollToElement($elementId: string): void {
    this.viewPosrtScroller.scrollToAnchor($elementId);
  }

  getCategory() {
    let catData = null;
    this.gs.getSiteCategory().subscribe(
      (data) => {
        if (data["status"] === true) {
          // console.log(data['data']);
          catData = data["data"] as CategoryMenu[];
          this.setMainMenu(catData);
        } else {
          this.setMainMenu();
        }
      },
      (err) => {
        this.setMainMenu();
      }
    );
  }

  logout() {
    this.isLoadingService.add();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
    this.authenticationService.logout();
    const loginURL = `${this.config.BASE_URL}login`;

    const settingLang = this.gs.getSettings("default_language");

    const changeLangCode =
      settingLang !== "" ? settingLang : this.langService.defaultLanguage;

    this.langService.getAvailableLanguages().map((lang) => {
      if (changeLangCode === lang.lang_code) {
        this.langService._setLanguage(lang.lang_code);
        localStorage.setItem("defaultLang", JSON.stringify(lang));
      }
    });

    this.langService
      .getLanguageTranslation(this.langService.getCurrentLanguage())
      .subscribe((translationsJSON) => {
        this.langService.setLanguageTranslation(translationsJSON);
        window.location.href = loginURL;
      });
  }

  setMainMenu(subMenu: CategoryMenu[] = null) {
    this.categoryMenu = [];
    if (subMenu !== null) {
      subMenu.forEach((element) => {
        let submenuItems = [];

        if (element.subCategoryList && element.subCategoryList.length > 0) {
          element.subCategoryList.forEach((submenu) => {
            submenuItems.push({
              title: submenu.subCategoryName,
              id: submenu.subCategoryId,
              url: `${this.config.BASE_URL}/category/${element.categoryId}/${submenu.subCategoryId}`,
              child: [],
            });
          });
        }

        this.categoryMenu.push({
          title: element.categoryName,
          id: element.categoryId,
          url: `${this.config.BASE_URL}/category/${element.categoryId}`,
          child: submenuItems,
        });
      });
    }

    this.mainMenu = [
      {
        title: "All",
        id: 0,
        url: this.sidenav,
        child: this.categoryMenu,
      },

      {
        title: "HotDeals",
        id: 0,
        url: `${this.config.BASE_URL}/hot-deals`,
        child: [],
      },
      {
        title: "LastMinuteDeals",
        id: 0,
        url: `${this.config.BASE_URL}/last-minute-deals`,
        child: [],
      },
      {
        title: "DesignerCatalogs",
        id: 0,
        url: `${this.config.BASE_URL}/designer-catalog`,
        child: [],
      },
      {
        title: "STORE_About_Us",
        id: 0,
        url: `${this.config.BASE_URL}/page/aboutus`,
        child: [],
      },
      // { title: 'FAQ', id: 0, url: `${this.config.BASE_URL}/faq`, child: [] },
      // { title: 'Terms_Conditions', id: 0, url: `${this.config.BASE_URL}/page/termsofuse`, child: [] },
      // { title: 'STORE_Privacy_Policy', id: 0, url: `${this.config.BASE_URL}/page/privacypolicy`, child: [] },
      // { title: 'Contact', id: 0, url: `${this.config.BASE_URL}/contact`, child: [] },
      // { title: 'Accent', id: 7, url: `${this.config.BASE_URL}/category/7`, child: [] },
      // { title: 'Mattresses', id: 8, url: `${this.config.BASE_URL}/category/8`, child: [] },
    ] as SiteMainMenu[];
  }

  getCartCount() {
    if (this.isUserLoggedIn) {
      this.cart.getCartCount().subscribe(
        (data) => {
          this.cartCount =
            typeof data["data"] !== "undefined" && data["data"] > 0
              ? data["data"]
              : 0;
          this.wishListCount =
            typeof data["wishlistCount"] !== "undefined" &&
            data["wishlistCount"] > 0
              ? data["wishlistCount"]
              : 0;
        },
        (err) => {
          this.cartCount = 0;
          this.wishListCount = 0;
        }
      );
    }
  }

  changeLanguage(selectedLang: string) {
    if (this.currentLanguage !== selectedLang) {
      this.isLoadingService.add();
      // this.ls.changeLanguage( lang );

      const changeLangCode = selectedLang;

      this.langService
        .getAllLanguages()
        .pipe()
        .subscribe((data) => {
          const apiLangs =
            typeof data["data"] !== "undefined" ? data["data"] : [];
          this.langService.setAvailableLanguages(apiLangs);
          apiLangs.map((lang) => {
            this.langService.languages.push(lang.lang_code);
            if (changeLangCode !== "") {
              if (changeLangCode === lang.lang_code) {
                this.langService._setLanguage(lang.lang_code);
                localStorage.setItem("defaultLang", JSON.stringify(lang));

                this.langService
                  .getLanguageTranslation(this.langService.getCurrentLanguage())
                  .pipe()
                  .subscribe((translationsJSON) => {
                    this.langService.setLanguageTranslation(translationsJSON);

                    if (this.isUserLoggedIn) {
                      this.authenticationService
                        .userLanguagePreference(lang.id)
                        .subscribe(
                          (data) => {
                            window.location.href = window.location.href;
                          },
                          (err) => {
                            //location.href = location.href;
                          }
                        );
                    } else {
                      window.location.href = window.location.href;
                    }
                  });
              }
            } else {
              if (lang.default_lang === "1") {
                this.langService.defaultLanguage = lang.lang_code;
                this.langService._setLanguage(lang.lang_code);
                localStorage.setItem("defaultLang", JSON.stringify(lang));

                this.langService
                  .getLanguageTranslation(this.langService.getCurrentLanguage())
                  .pipe()
                  .subscribe((translationsJSON) => {
                    this.langService.setLanguageTranslation(translationsJSON);
                    if (this.isUserLoggedIn) {
                      this.authenticationService
                        .userLanguagePreference(lang.id)
                        .subscribe(
                          (data) => {
                            window.location.href = window.location.href;
                          },
                          (err) => {
                            window.location.href = window.location.href;
                          }
                        );
                    } else {
                      window.location.href = window.location.href;
                    }
                  });
              }
            }
          });
        });

      // setTimeout(() => {
      //   location.reload();
      // }, 2000);
    }
  }

  loadJqueryFunction() {
    $(".dropdown-menu a.dropdown-toggle").on("click", function (e) {
      var $el = $(this);
      $el.toggleClass("active-dropdown");
      var $parent = $(this).offsetParent(".dropdown-menu");
      if (!$(this).next().hasClass("show")) {
        $(this)
          .parents(".dropdown-menu")
          .first()
          .find(".show")
          .removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass("show");

      $(this).parent("li").toggleClass("show");

      $(this)
        .parents("li.nav-item.dropdown.show")
        .on("hidden.bs.dropdown", function (e) {
          $(".dropdown-menu .show").addClass("show01");
          $(".dropdown-menu .show").removeClass("show");
          $el.removeClass("active-dropdown");
        });

      if (!$parent.parent().hasClass("navbar-nav")) {
        $el
          .next()
          .css({ top: $el[0].offsetTop, left: $parent.outerWidth() - 4 });
      }

      return false;
    });
  }

  // category slide nav menu
  categoryMainMenuClicked(item: any) {
    if (item.child && item.child.length > 0) {
      this.showCategorySubMenu = true;
      this.categorySubMenu = item.child;
    } else {
      this.navigateByUrl(item.url);
    }
  }

  onSubCategoryMenuClicked(item) {
    this.navigateByUrl(item.url);
  }

  navigateByUrl(url: string) {
    this.sidenav.close();

    this.router.navigateByUrl(url);
  }

  goBack() {
    this.showCategorySubMenu = false;
  }

  closeSideNav() {
    this.sidenav.close();
  }

  onClosedSlideNav() {
    this.showCategorySubMenu = false;
  }

  openSlideMenu(ele: any) {
    const slideNav = typeof ele !== "undefined" ? ele : this.sidenav;
    slideNav.open();
  }

  // category slide nav menu end

  isSticky: boolean = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    this.isSticky = offset > 100; // Apply sticky class after scrolling 100px
  }
}

interface SiteMainMenu {
  title: string;
  id: number;
  url: string;
  child: SiteMainMenu[];
}

interface CategoryMenu {
  categoryId: number;
  categoryName: string;
  subCategoryList?: SubCategory[];
}

interface SubCategory {
  categoryId: number;
  subCategoryId: number;
  subCategoryName: string;
}
