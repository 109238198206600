import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FixedElementService {
  private fixedElementHeightSource = new BehaviorSubject<number>(0); // Default height is 0
  fixedElementHeight$ = this.fixedElementHeightSource.asObservable();

  setHeight(height: number): void {
    this.fixedElementHeightSource.next(height);
  }
}
