import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "common/modules/material.module";
import { FilterSidebarComponent } from "./filter-sidebar/filter-sidebar.component";
import { DropdownToggleDirective } from "common/directives/dropdown-toggle.directive";
// import { ChatBotComponent } from './chat-bot/chat-bot.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FilterSidebarComponent,
    DropdownToggleDirective,
    // ChatBotComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    FormsModule
  ],
  exports: [HeaderComponent, FooterComponent, FilterSidebarComponent],
})
export class TemplateModule {}
